import React, { useState, useEffect } from "react";
// import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import axios from "axios";
import { baseURL } from "../Components/Config/Config";
import { Line, Doughnut } from "react-chartjs-2";
import "../assets/sass/_questions.scss";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "rsuite/dist/rsuite-no-reset.min.css";
import "../assets/sass/_statistics.scss";
import Export from "../assets/images/export.png";
// import Filter from "../assets/images/filter.png";
// import Form from "react-bootstrap/Form";
// import { Dropdown } from "react-bootstrap";
import Left from "../assets/images/arrow-left.png";
import Right from "../assets/images/arrow-right.png";
import Eye from "../assets/images/eye.png";
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SuccessIcon from "../assets/images/featured-icon.png";
import FailIcon from "../assets/images/failed-icon.png";
import FullScreen from "../assets/images/fullscreen.png";
import html2canvas from "html2canvas";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = () => {
  // State variables

  const [lineChartData, setLineChartData] = useState(null);
  const [userType, setUserType] = useState("e");
  const [data, setData] = useState(null);
  const [total, setTotal] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeReviewStats, setActiveReviewStats] = useState(null);
  const activeReviewId = localStorage.getItem("activeReviewId");
  const [reviews, setReviews] = useState([]);
  const [selectedReviewId, setSelectedReviewId] = useState(activeReviewId);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [employeeReviews, setEmployeeReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [useDateRangeData, setUseDateRangeData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [modalStatus, setModalStatus] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const fetchData = async (reviewId = selectedReviewId) => {
      setLoading(true);
      try {
        let apiParams = {
          user_type: userType,
          page: currentPage,
          search: searchTerm,
          per_page: rowsPerPage,
        };

        // If date range data is not requested, use default behavior with selectedReviewId
        if (!useDateRangeData) {
          apiParams.reviewId = selectedReviewId;
        } else {
          if (startDate && endDate) {
            apiParams.start_date = startDate.toISOString().split("T")[0];
            apiParams.end_date = endDate.toISOString().split("T")[0];
          }
        }
        const lastWeekDataParams = { ...apiParams };
        delete lastWeekDataParams.user_type;
        // Fetch data based on conditions
        const [
          surveyStatsResponse,
          specificReviewStatsResponse,
          employeeReviewsResponse,
          averageRatingResponse,
        ] = await Promise.all([
          axios.get(baseURL + `review/comparison/${selectedReviewId}`, {
            params: apiParams,
          }),
          axios.get(baseURL + `get-specific-review-stats/${selectedReviewId}`, {
            params: apiParams,
          }),
          axios.get(`${baseURL}review/employee-data/${selectedReviewId}`, {
            params: apiParams,
          }),
          axios.get(baseURL + "reviews/specific-review_avg", {
            params: lastWeekDataParams,
          }),
        ]);

        const surveyStatsData = surveyStatsResponse.data.data;
        const specificReviewStatsData = specificReviewStatsResponse.data;
        const employeeReviewsData = employeeReviewsResponse.data.data;
        const averageRatingData =
          averageRatingResponse.data.data_by_day_of_week;

        // Update state with fetched data
        const newSurveyStatsData = {
          labels: ["Employee", "Guest"],
          datasets: [
            {
              label: "Review Count",
              data: surveyStatsData.map((entry) => entry.review_count),
              backgroundColor: ["#9787FF", "#E63873"],
            },
          ],
        };
        setData(newSurveyStatsData);
        setActiveReviewStats(specificReviewStatsData);
        setEmployeeReviews(employeeReviewsData);

        setTotalPages(employeeReviewsResponse.data.last_page);

        const lastWeekLabels = averageRatingData.map(
          (entry) => entry.day_of_week
        );
        const lastWeekValues = averageRatingData.map((entry) =>
          parseFloat(entry.average_rating)
        );

        const lastWeekFormattedData = {
          labels: lastWeekLabels,
          datasets: [
            {
              label: "Average Rating by Day of the Week",
              data: lastWeekValues,
              fill: false,
              borderColor: "#9787FF",
              tension: 0.1,
              pointRadius: 5,
            },
          ],
        };

        setLineChartData(lastWeekFormattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      try {
        const response = await axios.get(baseURL + "all-reviews");
        setReviews(response.data.reviews);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    useDateRangeData,
    selectedReviewId,
    startDate,
    endDate,
    userType,
    currentPage,
    searchTerm,
    rowsPerPage,
  ]);

  const handleDateRangeChange = async (newDates) => {
    if (newDates !== null) {
      const newStartDate = newDates[0] ? new Date(newDates[0]) : null;
      const newEndDate = newDates[1] ? new Date(newDates[1]) : null;

      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setUseDateRangeData(true); // Set flag to true when date range is selected
    } else {
      setUseDateRangeData(false); // Set flag to false when date range is cleared
    }
  };
  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const formatTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedTime = dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedTime;
  };
  const exportToExcel = () => {
    axios
      .get(baseURL + `export-specific-review/${selectedReviewId}`, {
        params: { user_type: "all" },
      })
      .then((response) => {
        const data = response.data;
        const users = data.users;

        const workbook = XLSX.utils.book_new();
        let nameList = [];
        let count = 1;

        users.forEach((user, index) => {
          const userInfo = user.user_info;

          const userAnswers = user.answers.map((answer) => ({
            EmployeeName: userInfo.name,
            Question_title: answer.question_title.split("@@@")[0],
            "Highly Unsatisfactory": answer["Highly Unsatisfactory"],
            Unsatisfactory: answer.Unsatisfactory,
            Neutral: answer.Neutral,
            Satisfactory: answer.Satisfactory,
            "Highly Satisfactory": answer["Highly Satisfactory"],
            Average: answer.average_rating,
          }));

          const userNotes = user.notes.map((note) => ({
            EmployeeName: userInfo.name,
            Notes: note.notes,
            Date: formatDate(note.created_at),
            Time: formatTime(note.created_at),
          }));

          const answersWorksheet = XLSX.utils.json_to_sheet(userAnswers);
          const notesWorksheet = XLSX.utils.json_to_sheet(userNotes);

          let newName = userInfo.name;
          if (newName.length > 21) {
            // Shorten the name
            newName = newName.substring(0, 20);
          }
          if (nameList.includes(userInfo.name)) {
            newName = newName + count;
            count += 1;
          } else {
            nameList.push(userInfo.name);
            count = 0;
          }
          console.log(
            'newName + "_Notes" + index :>> ',
            (newName + "_Notes").length
          );
          XLSX.utils.book_append_sheet(
            workbook,
            answersWorksheet,
            newName + "_Answers" + index
          );
          XLSX.utils.book_append_sheet(
            workbook,
            notesWorksheet,
            newName + "_Notes" + index
          );
        });

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.xlsx";
        document.body.appendChild(link);
        link.click();

        console.log("Excel file exported successfully.");
        setShowModal(true);
        setModalImage(SuccessIcon);
        setModalStatus("Export Successful");
        setModalMessage("The data has been exported successfully.");
      })
      .catch((error) => {
        console.error("Error fetching or processing data:", error);

        setShowModal(true);
        setModalImage(FailIcon);
        setModalStatus("Export Failed");
        setModalMessage("Failed to export data. Please try again later.");
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImage(null);
    setModalStatus("");
    setModalMessage("");
  };
  const handleReviewSelect = (reviewId) => {
    setSelectedReviewId(reviewId);
    fetchReviewStats(reviewId);
  };
  const fetchReviewStats = async (reviewId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + `get-specific-review-stats/${reviewId}`
      );
      setActiveReviewStats(response.data);
      console.log("response :>> ", activeReviewStats);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Search function
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: true,
          drawTicks: true,
          borderDash: [5, 5],
          color: "#9787FF",
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: 500,
          beginAtZero: true,
          padding: 10,
          font: {
            size: 14,
            weight: 300,
            family: "Roboto",
            style: "normal",
            lineHeight: 2,
          },
          color: "#A1A7C4",
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
          color: "#fff",
        },
        ticks: {
          display: true,
          color: "#A1A7C4",
          padding: 10,
          font: {
            size: 14,
            weight: 300,
            family: "Roboto",
            style: "normal",
            lineHeight: 2,
          },
        },
      },
    },
  };
  const exportAsImage = () => {
    const sectionToCapture = document.getElementById("exportable-section");
    html2canvas(sectionToCapture).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "exported_section.png";
      link.click();
    });
  };
  const toggleFullScreen = () => {
    const sectionToFullScreen = document.getElementById("exportable-section");

    if (sectionToFullScreen) {
      if (!document.fullscreenElement) {
        sectionToFullScreen
          .requestFullscreen()
          .then(() => {
            console.log("Entered full-screen mode successfully.");
          })
          .catch((err) => {
            console.error("Error attempting to enter full-screen mode:", err);
          });
      } else {
        document
          .exitFullscreen()
          .then(() => {
            console.log("Exited full-screen mode successfully.");
          })
          .catch((err) => {
            console.error("Error attempting to exit full-screen mode:", err);
          });
      }
    } else {
      console.error("Element with ID 'exportable-section' not found.");
    }
  };

  return (
    <div className="reviews admin-dashboard analysis">
      <Sidebar />
      <div className="main">
        <h2>
          Survey Statistics
          <div className="stats-filters">
            <DateRangePicker
              appearance="default"
              placeholder="Date"
              style={{ width: 230 }}
              onChange={handleDateRangeChange}
            />
            <button className="btn btn-default" onClick={exportToExcel}>
              <img src={Export} alt="Export" />
              Export
            </button>
            <select
              className="form-select"
              value={selectedReviewId}
              onChange={(e) => handleReviewSelect(e.target.value)}
            >
              {reviews.map((review) => (
                <option key={review.id} value={review.id}>
                  {review.name}
                </option>
              ))}
            </select>
          </div>
        </h2>

        <div className="row mb-4">
          <div className="col-md-6 d-flex">
            <div className="stats-box w-100">
              <h2>Average Rating</h2>
              {loading ? (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div class="spinner-border d-flex" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : lineChartData ? (
                <Line
                  data={lineChartData}
                  className="custom-line-chart"
                  options={options}
                  height={100}
                  style={{ height: "150px", width: "100%" }}
                />
              ) : (
                <p className="text-center">No Data Found</p>
              )}
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="stats-box w-100">
              <h2>Employee vs Guests</h2>

              <h3>
                {total}
                <br />
                <span>Reviews</span>
              </h3>
              {data ? (
                <Doughnut data={data} />
              ) : (
                <p className="text-center">No Data Found</p>
              )}
            </div>
          </div>
        </div>
        <div className="view-full-btns">
          <button className="btn btn-default" onClick={exportAsImage}>
            <img className="mr-2" src={Export} alt="Export" />
            Export Image
          </button>
          <button className="btn btn-default" onClick={toggleFullScreen}>
            <img
              style={{ width: "20px" }}
              className="img-fluid"
              src={FullScreen}
              alt="full-screen"
            />
          </button>
        </div>
        <div className="questions-table mt-3" id="exportable-section">
          <div className="questions-header">
            <div className="d-flex align-items-center justify-content-between w-100">
              <h5>Survey Statistics</h5>
            </div>
          </div>
          <div className="table-responsive">
            {loading ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="spinner-border d-flex" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : activeReviewStats &&
              activeReviewStats.active_review_questions.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Average</th>
                    {activeReviewStats.active_review_questions[0] &&
                      Object.keys(activeReviewStats.active_review_questions[0])
                        .filter(
                          (key) =>
                            key !== "question_id" &&
                            key !== "question_title" &&
                            key !== "englishQuestion" &&
                            key !== "Average" // Exclude the "average_rating" key from headers
                        )
                        .map((key) => (
                          <th key={key}>{key.replace(/_/g, " ")}</th>
                        ))}
                  </tr>
                </thead>
                <tbody>
                  {activeReviewStats.active_review_questions.map((question) => (
                    <tr key={question.question_id}>
                      <td>{question?.question_title.split("@@@")[0].trim()}</td>
                      {/* Displaying the average rating in the second column */}
                      <td>
                        {question.Average
                          ? parseFloat(question.Average).toFixed(1)
                          : ""}
                      </td>
                      {/* Mapping through other responses */}
                      {Object.keys(question)
                        .filter(
                          (key) =>
                            key !== "question_id" &&
                            key !== "question_title" &&
                            key !== "englishQuestion" &&
                            key !== "Average" // Exclude the "average_rating" key from other responses
                        )
                        .map((key) => (
                          <td key={`${question.id}-${key}`}>
                            {key === "Average"
                              ? parseFloat(question[key]).toFixed(1)
                              : question[key]}
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center">No Data Found</p>
            )}
          </div>
        </div>

        <div className="questions-table mt-3">
          <div className="questions-header">
            <div>
              <h5>Employee Reviews</h5>
            </div>
            {/* <input
              type="search"
              className="form-control"
              placeholder="Search Employee"
              value={searchTerm}
              onChange={handleSearch}
            /> */}
          </div>
          <div className="table-responsive">
            {loading ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="spinner-border d-flex" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : employeeReviews?.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Employee Id</th>
                    <th>Name</th>
                    <th>Action</th>
                    <th>Average</th>
                    {employeeReviews[0]?.counts &&
                      Object.keys(employeeReviews[0].counts).map((option) => (
                        <th key={option}>{option}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {employeeReviews?.map((employee) => (
                    <tr key={employee.employee_code}>
                      <td>{employee.employee_code}</td>
                      <td>{employee.name}</td>
                      <td>
                        <Link
                          to={`/employees/${employee.user_id}`}
                          className="btn btn-default view-btn"
                        >
                          <img src={Eye} alt="eye-icon" />
                          View
                        </Link>
                      </td>
                      <td>{employee?.avg.toFixed(1) || 0}</td>
                      {employee.counts &&
                        Object.values(employee.counts).map((count, index) => (
                          <td key={index}>{count}</td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center">No Data Found</p>
            )}
            <div className="pagination">
              <button
                className="btn btn-default"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <img src={Left} alt="prev" />
              </button>
              {Array.from({ length: totalPages > 5 ? 5 : totalPages }).map(
                (_, index) => {
                  const page = currentPage - 2 + index;
                  if (page > 0 && page <= totalPages) {
                    return (
                      <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={
                          currentPage === page
                            ? "btn btn-default active"
                            : "btn btn-default"
                        }
                      >
                        {page}
                      </button>
                    );
                  }
                  return null;
                }
              )}
              {totalPages > 5 && <span>...</span>}
              <button
                className="btn btn-default"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <img src={Right} alt="next" />
              </button>
              <select
                className="custom-select"
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={modalImage} alt="modal" style={{ marginRight: "10px" }} />
          <h3>{modalStatus}</h3>
          <p>{modalMessage}</p>
          {modalImage === FailIcon ? (
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Statistics;
