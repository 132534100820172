import React, { useState, useEffect } from "react";
import "../../assets/sass/_sidebar.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "../../assets/images/logout.png";
import Logo from "../../assets/images/logo-1.png";
import axios from "axios";
import { baseURL } from "../Config/Config";

const Sidebar = ({ reviewId, employeeId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("activeReviewId");
    navigate("/");
  };
  const [reviewsData, setReviewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(baseURL + "all-reviews").then((response) => {
      setReviewsData(response?.data?.reviews);
      console.log("response?.data?.reviews :>> ", response?.data?.reviews);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    if (reviewsData?.length > 0) {
      const activeReview = reviewsData?.find(
        (review) => parseInt(review.status) === 1
      );
      console.log("activeReview ", activeReview);
      if (activeReview) {
        localStorage.setItem("activeReviewId", activeReview.id);
      }
    }
  }, [reviewsData]);

  return (
    <sidebar className="sidebar">
      <div className="sidebar-logo">
        <Link to="/dashboard">
          <img className="img-fluid" src={Logo} alt="logo" />
        </Link>
      </div>
      <hr className="sidebar-divider" />
      <ul className="nav flex-column sidebar-list">
        <li
          className={`nav-item ${
            location.pathname === "/dashboard" ? "active" : ""
          }`}
        >
          <Link to="/dashboard" className="nav-link">
            Dashboard
          </Link>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/reviews"
              ? "active"
              : "" ||
                location.pathname === "/create-review" ||
                location.pathname === "/reviews/" + reviewId
              ? "active"
              : ""
              ? "active"
              : ""
          }`}
        >
          <Link to="/reviews" className="nav-link">
            All Surveys
          </Link>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/statistics" ? "active" : ""
          }`}
        >
          <Link to="/statistics" className="nav-link">
            Analysis
          </Link>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/employees"
              ? "active"
              : "" || location.pathname === "/employees/" + employeeId
              ? "active"
              : ""
          }`}
        >
          <Link to="/employees" className="nav-link">
            Employees
          </Link>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/guest"
              ? "active"
              : "" || location.pathname === "/guest/" + employeeId
              ? "active"
              : ""
          }`}
        >
          <Link to="/guest" className="nav-link">
            Guest
          </Link>
        </li>
        {/* <li
          className={`nav-item ${
            location.pathname === "/profile" ? "active" : ""
          }`}
        >
          <Link to="/profile" className="nav-link">
            Account
          </Link>
        </li> */}
        <button className="btn btn-default logout-btn" onClick={handleLogout}>
          <img src={LogoutIcon} alt="logout" />
          Logout
        </button>
      </ul>
    </sidebar>
  );
};

export default Sidebar;
