import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseURL } from "../Components/Config/Config";
import axios from "axios";
import "../assets/sass/_reviews.scss";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import CreateIcon from "../assets/images/create-icon.png";
import AddIcon from "../assets/images/add.png";
import Download from "../assets/images/download.svg";
import Circle from "../assets/images/circle.png";
import CircleGreen from "../assets/images/circle-green.png";
import "bootstrap/dist/css/bootstrap.min.css";

const Reviews = () => {
  const [reviewsData, setReviewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(baseURL + "all-reviews").then((response) => {
      setReviewsData(response?.data?.reviews);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    if (reviewsData?.length > 0) {
      const activeReview = reviewsData?.find((review) => review.status === 1);

      if (activeReview) {
        localStorage.setItem("activeReviewId", activeReview.id);
      }
    }
  }, [reviewsData]);
  const handleExport = async (reviewId) => {
    try {
      const response = await axios.get(baseURL + `review/export/${reviewId}`, {
        params: { user_type: "all" },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `survey_${reviewId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  const getTimeDifference = (updatedAt) => {
    const now = new Date();
    const updatedTime = new Date(updatedAt);
    const differenceInMs = now - updatedTime;
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    return `You edited ${differenceInDays} days ago`;
  };

  return (
    <div className="reviews admin-dashboard">
      <Sidebar />
      <div className="main">
        {/* <Header /> */}

        <h2>All Surveys</h2>
        <div className="all-reviews">
          <div className="survey-box">
            <div className="survey-cont">
              <Link to="/create-review">
                <img src={AddIcon} alt="create-icon" />
              </Link>
              <Link to="/create-review">Create Survey</Link>
            </div>
          </div>
          {loading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            reviewsData?.map((review) => (
              <div key={review.id} className="survey-box">
                <div className="survey-cont">
                  <Link to={`/reviews/${review?.id}`}>
                    <img
                      className="create-icon"
                      src={CreateIcon}
                      alt="create-icon"
                    />
                  </Link>
                  <Link className="survey-title" to={`/reviews/${review?.id}`}>
                    {review?.name}
                  </Link>
                  <div className="survey-edit">
                    <h3>
                      {review?.status == 1 ? (
                        <img src={CircleGreen} alt="edit-icon" />
                      ) : (
                        <img src={Circle} alt="edit-icon" />
                      )}
                      {review.total_users} responses
                    </h3>
                    <h3>{review.users_today} today</h3>
                  </div>
                  <div className="survey-edit">
                    <p>{getTimeDifference(review.updated_at)}</p>
                    <button
                      className="btn btn-default"
                      onClick={() => handleExport(review.id)}
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.1"
                          d="M22 16.2346V15.2346C22 12.4066 22 10.9926 21.121 10.1146C20.242 9.23462 18.828 9.23462 16 9.23462H8C5.171 9.23462 3.757 9.23462 2.878 10.1146C2 10.9916 2 12.4046 2 15.2316V16.2346C2 19.0636 2 20.4776 2.879 21.3566C3.757 22.2346 5.172 22.2346 8 22.2346H16C18.828 22.2346 20.243 22.2346 21.121 21.3566C22 20.4766 22 19.0636 22 16.2346Z"
                          fill="#9686FE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 1.48462C11.8011 1.48462 11.6103 1.56364 11.4697 1.70429C11.329 1.84494 11.25 2.03571 11.25 2.23462V13.2076L9.57 11.2466C9.50591 11.1718 9.42771 11.1103 9.33986 11.0656C9.25201 11.021 9.15622 10.9941 9.05798 10.9865C8.95973 10.9789 8.86095 10.9907 8.76727 11.0213C8.67359 11.0518 8.58685 11.1005 8.512 11.1646C8.43714 11.2287 8.37565 11.3069 8.33101 11.3948C8.28638 11.4826 8.25949 11.5784 8.25188 11.6766C8.24426 11.7749 8.25607 11.8737 8.28664 11.9673C8.3172 12.061 8.36591 12.1478 8.43 12.2226L11.43 15.7226C11.5004 15.805 11.5878 15.8711 11.6862 15.9164C11.7846 15.9617 11.8917 15.9852 12 15.9852C12.1083 15.9852 12.2154 15.9617 12.3138 15.9164C12.4122 15.8711 12.4996 15.805 12.57 15.7226L15.57 12.2226C15.6341 12.1478 15.6828 12.061 15.7134 11.9673C15.7439 11.8737 15.7557 11.7749 15.7481 11.6766C15.7405 11.5784 15.7136 11.4826 15.669 11.3948C15.6243 11.3069 15.5628 11.2287 15.488 11.1646C15.4131 11.1005 15.3264 11.0518 15.2327 11.0213C15.139 10.9907 15.0403 10.9789 14.942 10.9865C14.8438 10.9941 14.748 11.021 14.6601 11.0656C14.5723 11.1103 14.4941 11.1718 14.43 11.2466L12.75 13.2066V2.23462C12.75 2.03571 12.671 1.84494 12.5303 1.70429C12.3897 1.56364 12.1989 1.48462 12 1.48462Z"
                          fill="#9686FE"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
