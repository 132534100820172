import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = (props) => {
  const { Component } = props;
  const user = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (user == "") {
      navigate("/");
    }
  }, [user]);
  return (
    <div>
      <Component />
    </div>
  );
};

export default Protected;
