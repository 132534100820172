import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Components/Sidebar/Sidebar";
import Header from "../Components/Header/Header";
import "../assets/sass/_reviews.scss";
import { baseURL } from "../Components/Config/Config";
import DeleteIcon from "../assets/images/delete.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SuccessIcon from "../assets/images/featured-icon.png";
import FailIcon from "../assets/images/failed-icon.png";

const Createreview = () => {
  const navigate = useNavigate();
  const [surveyTitle, setSurveyTitle] = useState("");
  const [questions, setQuestions] = useState([
    { english: "", hindi: "", arabic: "" },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleQuestionChange = (index, language, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][language] = value;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { english: "", hindi: "", arabic: "" }]);
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  useEffect(() => {
    let timeout;
    if (successMessage) {
      setShowSuccessModal(true);
      timeout = setTimeout(() => {
        setShowSuccessModal(false);
        navigate("/reviews");
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [successMessage, navigate]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setError("");
    setSuccessMessage("");

    const formattedQuestions = questions.map((question) => ({
      title: `${question.english} @@@ ${question.hindi} @@@ ${question.arabic}`,
    }));

    const surveyData = {
      name: surveyTitle,
      code: "Review Code",
      questions: formattedQuestions,
    };

    try {
      const response = await fetch(baseURL + "reviews/create-new-survey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(surveyData),
      });

      if (response.ok) {
        setSuccessMessage("Survey created successfully!");
      } else {
        console.error("Failed to create survey:", response.statusText);
        setError("Failed to submit the form. Please try again later.");
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to submit the form. Please try again later.");
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseErrorModal = () => setShowErrorModal(false);

  return (
    <div className="reviews admin-dashboard">
      <Sidebar />
      <div className="main">
        {/* <Header /> */}
        <h2>Create Survey</h2>
        {/* Create Survey Title */}
        <div className="feedback-box">
          <label>Write your feedback title</label>
          <input
            type="text"
            className="form-control"
            value={surveyTitle}
            onChange={(e) => setSurveyTitle(e.target.value)}
          />
        </div>

        {/* Create Questions */}
        {questions.map((question, index) => (
          <div key={index} className="question-box">
            <h2>
              Question {index + 1}{" "}
              <button
                className="btn btn-default"
                onClick={() => handleDeleteQuestion(index)}
              >
                <img src={DeleteIcon} alt="delete-icon" />
              </button>
            </h2>

            <div className="feedback-box">
              <div className="form-group">
                <label>English</label>
                <input
                  type="text"
                  className="form-control"
                  value={question.english}
                  onChange={(e) =>
                    handleQuestionChange(index, "english", e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label>Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  value={question.hindi}
                  onChange={(e) =>
                    handleQuestionChange(index, "hindi", e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label>Arabic</label>
                <input
                  type="text"
                  className="form-control"
                  value={question.arabic}
                  onChange={(e) =>
                    handleQuestionChange(index, "arabic", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        ))}
        {error && <div className="error-message">{error}</div>}

        {/* Add Survey Question Button */}
        <div className="feedback-box">
          <div className="add-question">
            <button className="btn btn-default" onClick={addQuestion}>
              + Add Question
            </button>
            <button
              className="btn btn-default"
              onClick={handleSubmit}
              disabled={
                isLoading || questions.length === 0 || surveyTitle.trim() === ""
              }
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={SuccessIcon} alt="modal" style={{ marginRight: "10px" }} />
          <h3>Success!</h3>
          <p>{successMessage}</p>
        </Modal.Body>
      </Modal>
      <Modal
        show={showErrorModal}
        onHide={handleCloseErrorModal}
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={FailIcon} alt="modal" style={{ marginRight: "10px" }} />
          <h3>Error!</h3>
          <p>{error}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Createreview;
