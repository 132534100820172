import React, { useState, useEffect } from "react";
import axios from "axios";

import { baseURL } from "../Config/Config";
import "../../assets/sass/_charts.scss";
import { Line, Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Charts = ({ userType }) => {
  const [chartData, setChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [lastMonthData, setLastMonthData] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const user_type = userType;

        // Reviews count by month
        const countByMonthResponse = await axios.post(
          baseURL + "reviews/count-by-month",
          {
            user_type: user_type,
          }
        );
        const countByMonthData = countByMonthResponse.data;
        const labels = countByMonthData.reviews_by_month.map(
          (entry) => entry.month
        );
        const counts = countByMonthData.reviews_by_month.map(
          (entry) => entry.total_reviews
        );
        const formattedData = {
          labels: labels,
          datasets: [
            {
              label: "Monthly Reviews",
              data: counts,
              fill: true,
              tension: 0,
              pointRadius: 5,
              pointBorderColor: "transparent",
              pointBackgroundColor: "rgba(255, 255, 255, .8)",
              borderColor: "rgba(255, 255, 255, .8)",
              borderWidth: 4,
              backgroundColor: "transparent",
              maxBarThickness: 6,
            },
          ],
        };
        setChartData(formattedData);

        // Last week data
        const lastWeekResponse = await axios.get(
          baseURL + "reviews/last-weak-data",
          {
            params: { user_type: user_type },
          }
        );
        const lastWeekData = lastWeekResponse.data.data_by_day_of_week;

        const lastWeekLabels = lastWeekData.map((entry) => entry.day_of_week);
        const lastWeekValues = lastWeekData.map((entry) =>
          parseFloat(entry.average_rating)
        );

        const lastWeekFormattedData = {
          labels: lastWeekLabels,
          datasets: [
            {
              label: "Average Rating by Day of the Week",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              data: lastWeekValues,
              maxBarThickness: 6,
            },
          ],
        };

        setBarChartData(lastWeekFormattedData);

        // Last month data
        const lastMonthResponse = await axios.get(
          baseURL + "reviews/last-month-data",
          {
            params: { user_type: user_type },
          }
        );
        const lastMonthData = lastMonthResponse.data.data_by_month;
        const lastMonthLabels = lastMonthData.map((entry) => entry.month);
        const lastMonthRatings = lastMonthData.map((entry) =>
          parseFloat(entry.average_rating)
        );
        const lastMonthFormattedData = {
          labels: lastMonthLabels,
          datasets: [
            {
              label: "Average Rating Last Month",
              data: lastMonthRatings,
              fill: true,
              tension: 0,
              pointRadius: 5,
              pointBorderColor: "transparent",
              pointBackgroundColor: "rgba(255, 255, 255, .8)",
              borderColor: "rgba(255, 255, 255, .8)",
              borderWidth: 4,
              backgroundColor: "transparent",
              maxBarThickness: 6,
            },
          ],
        };
        setLastMonthData(lastMonthFormattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userType]);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
          color: "rgba(255, 255, 255, .2)",
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: 500,
          beginAtZero: true,
          padding: 10,
          font: {
            size: 14,
            weight: 300,
            family: "Roboto",
            style: "normal",
            lineHeight: 2,
          },
          color: "#fff",
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
          color: "rgba(255, 255, 255, .2)",
        },
        ticks: {
          display: true,
          color: "#f8f9fa",
          padding: 10,
          font: {
            size: 14,
            weight: 300,
            family: "Roboto",
            style: "normal",
            lineHeight: 2,
          },
        },
      },
    },
  };

  return (
    <div className="charts">
      <div className="charts-by-month">
        {loading ? (
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            {" "}
            <div className="chart-box">
              <div className="chart-box-week">
                {barChartData && (
                  <Bar
                    data={barChartData}
                    options={options}
                    style={{ height: "200px", width: "100%" }}
                  />
                )}
              </div>
              <h3>Daily Ratings</h3>
              <p>Current Week</p>
              <hr className="divider" />
            </div>
            <div className="chart-box">
              <div className="chart-box-month">
                {lastMonthData && (
                  <Line
                    data={lastMonthData}
                    options={options}
                    style={{ height: "200px", width: "100%" }}
                  />
                )}
              </div>
              <h3>Monthly Ratings</h3>
              <p>Current Week</p>
              <hr className="divider" />
            </div>
            <div className="chart-box">
              <div className="chart-box-rating">
                {chartData && (
                  <Line
                    data={chartData}
                    options={options}
                    style={{ height: "200px", width: "100%" }}
                  />
                )}
              </div>
              <h3>Monthly Reviews</h3>
              <p>Current Month</p>
              <hr className="divider" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Charts;
