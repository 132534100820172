import React, { useState } from "react";
import "../../assets/sass/_login.scss";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../Components/Config/Config";
import Logo from "../../assets/images/logo.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state when submitting form
    try {
      const response = await axios.post(baseURL + "login", {
        email: email,
        password: password,
      });

      const { access_token } = response.data;
      localStorage.setItem("token", access_token);
      setIsLoggedIn(true);
      navigate("/dashboard");
    } catch (error) {
      setError("Invalid email or password. Please try again.");
    } finally {
      setIsLoading(false); // Reset loading state after API call completes
    }
  };

  return (
    <div className="login-main">
      <div className="login-aside">
        <img className="img-fluid" src={Logo} alt="logo" />
        <h5>
          Welcome to Feedback Form SaaS App - Revolutionizing Client Feedback!
        </h5>
        <p>
          We understand the value of client feedback in shaping businesses for
          success.
        </p>
      </div>
      <div className="login-cont">
        <div>
          <h2>welcome</h2>
          <h3>Please login to access your account. </h3>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">
                Email<sup>*</sup>
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div>
              <label htmlFor="password">
                Password<sup>*</sup>
              </label>
              <div className="show-hide-pass">
                <input
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <span
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
            </div>

            <button
              className="btn btn-default"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>
          </form>
          {error && <p className="error">{error}</p>}
          <p>
            Forgot Password? <Link to="/reset-password">Reset Now</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
