import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Components/Sidebar/Sidebar";
import Header from "../Components/Header/Header";
import { baseURL } from "../Components/Config/Config";
import Left from "../assets/images/arrow-left.png";
import Right from "../assets/images/arrow-right.png";
import ImportIcon from "../assets/images/import.png";
import Eye from "../assets/images/eye.png";
import SuccessIcon from "../assets/images/featured-icon.png";
import FailIcon from "../assets/images/failed-icon.png";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as XLSX from "xlsx";
import Export from "../assets/images/export.png";

const Guest = () => {
  const [allEmployees, setAllEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalStatus, setModalStatus] = useState("");
  const [modalImage, setModalImage] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const activeReviewId = localStorage.getItem("activeReviewId");
  const [selectedReviewId, setSelectedReviewId] = useState(activeReviewId);
  // const [totalEmployee, settotalEmployee] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          baseURL +
            `users/get-list?page=${currentPage}&per_page=${rowsPerPage}`,
          { params: { user_type: "g" } }
        );
        setAllEmployees(response.data?.users?.data);
        setFilteredEmployees(response.data?.users?.data);
        // settotalEmployee(response.data?.total_number_of_users);
        setTotalPages(response.data?.users?.last_page);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, rowsPerPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    try {
      if (query === "") {
        // If search query is empty, reset to show first 10 employees
        setFilteredEmployees(allEmployees.slice(0, rowsPerPage));
      } else {
        const response = await axios.post(baseURL + "users/search", {
          query,
          user_type: "e",
        });
        setFilteredEmployees(response.data.slice(0, rowsPerPage)); // Show first page of search results
        setTotalPages(1);
      }
    } catch (error) {
      console.error("Error searching employees:", error);
    }
  };

  const handleFileUpload = async (files) => {
    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        baseURL + "add-user-in-db-from-excel",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setModalStatus("Import Successful!");
      setModalMessage(response?.data?.message);
      setModalImage(SuccessIcon);
      setShowModal(true);
    } catch (error) {
      console.error("Error uploading file:", error);
      setModalStatus("Import failed!");
      setModalMessage("Import Failed. Please try again later.");
      setModalImage(FailIcon);
      setShowModal(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: ".xlsx, .xls",
    multiple: false,
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage("");
  };
  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const formatTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedTime = dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedTime;
  };
  const exportToExcel = () => {
    axios
      .get(baseURL + `export-specific-review/${selectedReviewId}`, {
        params: { user_type: "g" },
      })
      .then((response) => {
        const data = response.data;
        const users = data.users;

        const workbook = XLSX.utils.book_new();
        let nameList = [];
        let count = 1;

        users.forEach((user, index) => {
          const userInfo = user.user_info;

          const userAnswers = user.answers.map((answer) => ({
            EmployeeName: userInfo.name,
            Question_title: answer.question_title.split("@@@")[0],
            "Highly Unsatisfactory": answer["Highly Unsatisfactory"],
            Unsatisfactory: answer.Unsatisfactory,
            Neutral: answer.Neutral,
            Satisfactory: answer.Satisfactory,
            "Highly Satisfactory": answer["Highly Satisfactory"],
            Average: answer.average_rating,
          }));

          const userNotes = user.notes.map((note) => ({
            EmployeeName: userInfo.name,
            Notes: note.notes,
            Date: formatDate(note.created_at),
            Time: formatTime(note.created_at),
          }));

          const answersWorksheet = XLSX.utils.json_to_sheet(userAnswers);
          const notesWorksheet = XLSX.utils.json_to_sheet(userNotes);

          let newName = userInfo.name;
          if (newName.length > 21) {
            // Shorten the name
            newName = newName.substring(0, 20);
          }
          if (nameList.includes(userInfo.name)) {
            newName = newName + count;
            count += 1;
          } else {
            nameList.push(userInfo.name);
            count = 0;
          }
          console.log(
            'newName + "_Notes" + index :>> ',
            (newName + "_Notes").length
          );
          XLSX.utils.book_append_sheet(
            workbook,
            answersWorksheet,
            newName + "_Answers" + index
          );
          XLSX.utils.book_append_sheet(
            workbook,
            notesWorksheet,
            newName + "_Notes" + index
          );
        });

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.xlsx";
        document.body.appendChild(link);
        link.click();

        console.log("Excel file exported successfully.");
        setShowModal(true);
        setModalImage(SuccessIcon);
        setModalStatus("Export Successful");
        setModalMessage("The data has been exported successfully.");
      })
      .catch((error) => {
        console.error("Error fetching or processing data:", error);

        setShowModal(true);
        setModalImage(FailIcon);
        setModalStatus("Export Failed");
        setModalMessage("Failed to export data. Please try again later.");
      });
  };

  return (
    <div className="reviews admin-dashboard employees">
      <Sidebar />
      <div className="main">
        {/* <Header /> */}
        <h2>
          Guest{" "}
          <button className="btn btn-default" onClick={exportToExcel}>
            <img src={Export} alt="Export" />
            Export
          </button>
        </h2>

        <div className="questions-table">
          <div className="questions-header">
            <div>
              <h5>Guest List</h5>
            </div>
            <input
              type="search"
              className="form-control"
              placeholder="Search Guest"
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
          {loading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div class="spinner-border d-flex" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : filteredEmployees?.length === 0 ? (
            <div className="no-data text-center">No data found</div>
          ) : (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEmployees?.map((employee) => (
                    <tr key={employee.id}>
                      <td>{employee.company_name}</td>
                      <td>{employee.name}</td>
                      <td>
                        <Link
                          to={`/guest/${employee.id}`}
                          className="btn btn-default view-btn"
                        >
                          <img src={Eye} alt="eye-icon" />
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="pagination">
            <button
              className="btn btn-default"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <img src={Left} alt="prev" />
            </button>
            {Array.from({ length: totalPages > 5 ? 5 : totalPages }).map(
              (_, index) => {
                const page = currentPage - 2 + index;
                if (page > 0 && page <= totalPages) {
                  return (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={
                        currentPage === page
                          ? "btn btn-default active"
                          : "btn btn-default"
                      }
                    >
                      {page}
                    </button>
                  );
                }
                return null;
              }
            )}
            {totalPages > 5 && <span>...</span>}
            <button
              className="btn btn-default"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <img src={Right} alt="next" />
            </button>
            <select
              className="custom-select"
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={modalImage} alt="modal" style={{ marginRight: "10px" }} />
          <h3>{modalStatus}</h3>
          <p>{modalMessage}</p>
          {modalImage === FailIcon ? (
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Guest;
