import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../Config/Config";
import "../../assets/sass/_ratings.scss";
import PrintIcon from "@mui/icons-material/Print";
import PersonIcon from "@mui/icons-material/Person";

const Ratings = () => {
  const [reviewsAverage, setReviewsAverage] = useState("");
  // const [employeesAverageRatings, setEmployeesAverageRatings] = useState("");
  // const [guestAverageRatings, setGuestAverageRatings] = useState("");
  const [totalReviews, setTotalReviews] = useState("");
  const [loading, setLoading] = useState("");
  useEffect(() => {
    // Set loading state to true initially
    setLoading(true);

    // Fetch data
    axios
      .get(baseURL + "reviews/average-rating")
      .then((response) => {
        setReviewsAverage(response.data);
      })
      .catch((error) => {
        console.error("Error fetching average ratings:", error);
      });

    axios
      .get(baseURL + "reviews/count")
      .then((response) => {
        setTotalReviews(response.data);
      })
      .catch((error) => {
        console.error("Error fetching total reviews:", error);
      })
      .finally(() => {
        // Set loading state to false when data fetching is completed
        setLoading(false);
      });
  }, []);

  return (
    <div className="top-ratings">
      <>
        <div className="ratings-box">
          <div className="ratings-box-header">
            <div className="icon-box">
              <PrintIcon />
            </div>
            <div>
              <p>Average Ratings</p>
              {reviewsAverage?.all?.average_rating ? (
                <h2>{reviewsAverage?.all?.average_rating?.toFixed(2)}</h2>
              ) : (
                <h2>0</h2>
              )}
            </div>
          </div>
          {/* <hr className="divider" /> */}
          {/* <h5>
            {reviewsAverage?.all?.difference_from_last_week ? (
              reviewsAverage.all.difference_from_last_week > 0 ? (
                <span style={{ color: "green" }}>
                  +{reviewsAverage.all.difference_from_last_week.toFixed(2)}%
                </span>
              ) : (
                <span style={{ color: "red" }}>
                  {reviewsAverage.all.difference_from_last_week.toFixed(2)}%
                </span>
              )
            ) : (
              <span>No change from last week</span>
            )}
          </h5> */}
        </div>
        <div className="ratings-box">
          <div className="ratings-box-header">
            <div className="icon-box">
              <PersonIcon />
            </div>
            <div>
              <p>Employees Average Rating</p>
              {reviewsAverage?.e?.average_rating ? (
                <h2>{reviewsAverage?.e?.average_rating?.toFixed(2)}</h2>
              ) : (
                <h2>0</h2>
              )}
            </div>
          </div>
          {/* <hr className="divider" /> */}
          {/* <h5>
            {reviewsAverage?.e?.difference_from_last_week ? (
              reviewsAverage.e.difference_from_last_week > 0 ? (
                <span style={{ color: "green" }}>
                  +{reviewsAverage.e.difference_from_last_week.toFixed(2)}
                </span>
              ) : (
                <span style={{ color: "red" }}>
                  {reviewsAverage.e.difference_from_last_week.toFixed(2)}
                </span>
              )
            ) : (
              <span>No change from last Month</span>
            )}
          </h5> */}
        </div>
        <div className="ratings-box">
          <div className="ratings-box-header">
            <div className="icon-box">
              <PersonIcon />
            </div>
            <div>
              <p>Guest Average Ratings</p>
              {reviewsAverage?.g?.average_rating ? (
                <h2>{reviewsAverage?.g?.average_rating?.toFixed(2)}</h2>
              ) : (
                <h2>0</h2>
              )}
            </div>
          </div>
          {/* <hr className="divider" /> */}
          {/* <h5>
            {reviewsAverage?.g?.difference_from_last_week ? (
              reviewsAverage.g.difference_from_last_week > 0 ? (
                <span style={{ color: "green" }}>
                  +{reviewsAverage.g.difference_from_last_week.toFixed(2)}
                </span>
              ) : (
                <span style={{ color: "red" }}>
                  {reviewsAverage.g.difference_from_last_week.toFixed(2)}
                </span>
              )
            ) : (
              <span>No change from last week</span>
            )}
          </h5> */}
        </div>
        <div className="ratings-box">
          <div className="ratings-box-header">
            <div className="icon-box">
              <PrintIcon />
            </div>
            <div>
              <p>Total Reviews</p>
              <h2>{totalReviews?.total_reviews}</h2>
            </div>
          </div>
          {/* <hr className="divider" /> */}
        </div>
      </>
    </div>
  );
};

export default Ratings;
