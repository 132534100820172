import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { baseURL } from "../Config/Config";
import "../../assets/sass/_questions.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Questions = ({ userType }) => {
  console.log("userType questions:>> ", userType);
  const [totalReviews, setTotalReviews] = useState("");
  const [reviewQuestions, setReviewQuestions] = useState([]);
  const [latestUsers, setLatestUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [englishQuestion, setEnglishQuestion] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const user_type = userType;
        const questionsResponse = await axios.get(
          baseURL + "question/get-active-review-questions-with-stats",
          {
            params: { user_type: user_type },
          }
        );
        const totalReviewsResponse = await axios.get(baseURL + "reviews/count");
        const latestUsersResponse = await axios.get(
          baseURL + "users/latest-with-answers"
        );

        const questions = questionsResponse.data.active_review_questions.map(
          (question) => ({
            ...question,
            englishQuestion: question.question_title
              ? question.question_title.split("@@@")[0].trim()
              : "",
          })
        );

        setReviewQuestions(questions);
        setTotalReviews(totalReviewsResponse.data);
        setLatestUsers(latestUsersResponse.data.latest_users);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    axios.get(baseURL + "reviews/count").then((response) => {
      setTotalReviews(response.data);
    });
    const fetchLatestUsers = async () => {
      try {
        const response = await axios.get(baseURL + "users/latest-with-answers");
        setLatestUsers(response.data.latest_users);
        console.log(
          "response.data.latest_users :>> ",
          response.data.latest_users
        );
      } catch (error) {
        console.error("Error fetching latest users:", error);
      }
    };

    fetchLatestUsers();
  }, [userType]);
  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-md-8">
          <div className="questions-table">
            <div className="questions-header">
              <div>
                <h5>Questions</h5>
                <p>
                  <CheckIcon />{" "}
                  <strong>{totalReviews?.total_reviews} reviews</strong> this
                  month
                </p>
              </div>
              <button className="btn btn-default">
                <MoreVertIcon />
              </button>
            </div>
            {loading ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div class="spinner-border d-flex" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : reviewQuestions.length > 0 ? (
              <div className="table-responsive">
                <table className="table ">
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Average</th>
                      {reviewQuestions.length > 0 &&
                        Object.keys(reviewQuestions[0])
                          .filter(
                            (key) =>
                              key !== "question_id" &&
                              key !== "question_title" &&
                              key !== "englishQuestion" &&
                              key !== "Average"
                          )
                          .map((key) => (
                            <th key={key}>{key.replace(/_/g, " ")}</th>
                          ))}
                    </tr>
                  </thead>
                  <tbody>
                    {reviewQuestions?.map((question) => (
                      <tr key={question.id}>
                        <td>{question?.englishQuestion}</td>
                        {/* Displaying the average rating in the second column */}
                        <td>
                          {question.Average
                            ? parseFloat(question.Average).toFixed(1)
                            : ""}
                        </td>
                        {/* Mapping through other responses */}
                        {Object.keys(question)
                          .filter(
                            (key) =>
                              key !== "question_id" &&
                              key !== "question_title" &&
                              key !== "englishQuestion" &&
                              key !== "Average" // Exclude the "Average" key from other responses
                          )
                          .map((key) => (
                            <td key={`${question.id}-${key}`}>
                              {question[key]}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-center">No data found</p>
            )}
          </div>
        </div>
        <div className="col-md-4 d-flex">
          <div className="w-100 latest-reviews">
            <div className="questions-header">
              <div>
                <h5>Last 10 Reviews</h5>
                {/* <p>
                  <CheckIcon />{" "}
                  <strong>{totalReviews?.total_reviews} reviews</strong> this
                  month
                </p> */}
              </div>
            </div>
            <div>
              <ul className="list-unstyled reviews-list">
                {latestUsers.map((user) => (
                  <li key={user.id}>
                    {user.name} ({user.average_rating})<p>{user.created_at}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
