import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { baseURL } from "../Components/Config/Config";
import "../assets/sass/_reviews.scss";
// import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import DeleteIcon from "../assets/images/delate-white.png";

const ReviewDetailComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    axios
      .get(baseURL + `get-review-question/${params.reviewId}`)
      .then((response) => {
        setReviewData(response.data.review);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching review details:", error);
        setError("Error fetching review details. Please try again later.");
        setLoading(false);
      });
  }, [params]);

  const handleDeleteSurvey = () => {
    axios
      .delete(baseURL + `delete-review/${params.reviewId}`)
      .then((response) => {
        navigate("/reviews");
      })
      .catch((error) => {
        console.error("Error deleting survey:", error);
        setError("Error deleting survey. Please try again later.");
      });
  };
  const handleActivateSurvey = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        baseURL + `activate-review/${params.reviewId}`,
        { status: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("activeReviewId", params.reviewId);
      window.location.reload();
    } catch (error) {
      console.error("Error activating survey:", error);
      setError("Error activating survey. Please try again later.");
    }
  };

  return (
    <div className="reviews admin-dashboard ">
      <Sidebar reviewId={params.reviewId} />
      <div className="main">
        {/* <Header /> */}

        {loading ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : reviewData ? (
          <div>
            <div className="feedback-box edit-survey">
              <h2 className="mb-0 ">
                Edit Survey
                <span>
                  {reviewData.status == 1 ? (
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                      disabled
                    >
                      Activated
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                      onClick={handleActivateSurvey}
                    >
                      Activate Survey
                    </button>
                  )}
                  {reviewData.status == 1 ? (
                    <button
                      className="btn btn-default"
                      onClick={handleDeleteSurvey}
                      disabled
                    >
                      <img src={DeleteIcon} alt="delete-icon" />
                      Delete Survey
                    </button>
                  ) : (
                    <button
                      className="btn btn-default"
                      onClick={handleDeleteSurvey}
                    >
                      <img src={DeleteIcon} alt="delete-icon" />
                      Delete Survey
                    </button>
                  )}
                </span>
              </h2>
            </div>

            {/* review Question */}
            <div className="feedback-box">
              <label>Feedback title</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={reviewData.name}
              />
            </div>

            {reviewData.questions.map((question, index) => (
              <div className="feedback-box" key={question.id}>
                <h2>Question no {index + 1}</h2>
                <div className="form-group">
                  <label>English</label>
                  <input
                    type="text"
                    className="form-control"
                    value={question.title.split("@@@")[0]}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label>Hindi</label>
                  <input
                    type="text"
                    className="form-control"
                    value={question.title.split("@@@")[1]}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label>Arabic</label>
                  <input
                    type="text"
                    className="form-control"
                    value={question.title.split("@@@")[2]}
                    disabled
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No review data available.</p>
        )}
      </div>
    </div>
  );
};

export default ReviewDetailComponent;
