import "./App.css";
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminDashboard from "./pages/AdminDashboard";
import ReviewDetailComponent from "./pages/ReviewDetailComponent";
import Login from "./pages/Login/Login";
import Protected from "./pages/Protected";
import Reviews from "./pages/Reviews";
import Employees from "./pages/Employees";
import Createreview from "./pages/Createreview";
import Statistics from "./pages/Statistics";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import EmployeeDetail from "./pages/EmployeeDetail";
import Guest from "./pages/Guest";
import GuestDetail from "./pages/GuestDetail";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {isLoggedIn && (
          <>
            <Route
              path="/dashboard"
              element={<Protected Component={AdminDashboard} />}
            />
            <Route path="/reviews" element={<Reviews />} />
            <Route
              path="/reviews/:reviewId"
              element={<ReviewDetailComponent />}
            />
            <Route path="/create-review" element={<Createreview />} />
            <Route path="/statistics" element={<Statistics />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/employees/:employeeId" element={<EmployeeDetail />} />
            <Route path="/guest/:employeeId" element={<GuestDetail />} />
            <Route path="/guest" element={<Guest />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
