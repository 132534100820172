import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { baseURL } from "../Components/Config/Config";
import Sidebar from "../Components/Sidebar/Sidebar";
import "../assets/sass/_admin.scss";
import Header from "../Components/Header/Header";
import Ratings from "../Components/Ratings/Ratings";
import Questions from "../Components/Questions/Questions";
import Charts from "../Components/Charts/Charts";

const AdminDashboard = (reviews) => {
  const [reviewsData, setReviewsData] = useState([]);

  const [statisticsData, setStatisticsData] = useState("");
  const [userType, setUserType] = useState("all");

  useEffect(() => {
    axios.get(baseURL + "all-reviews").then((response) => {
      setReviewsData(response?.data?.reviews);
    });
    // Fetch statistics data
    axios
      .get(baseURL + "reviews/statistics")
      .then((response) => {
        setStatisticsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching statistics:", error);
      });
  }, []);

  return (
    <>
      <div className="admin-dashboard container-fluid">
        <Sidebar />
        <div className="main">
          {/* <Header /> */}
          <Ratings />
          <div>
            <select
              value={userType}
              className="custom-select"
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value="all">All</option>
              <option value="e">Employee</option>
              <option value="g">Guest</option>
            </select>
          </div>
          <Charts userType={userType} />
          <Questions userType={userType} />

          {/* {statisticsData && (
            <div>
              <h2>Review Statistics</h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>Total Users</th>
                    <th>Unsatisfactory</th>
                    <th>Below Expectation</th>
                    <th>Average</th>
                    <th>Good</th>
                    <th>Excellent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{statisticsData.total_users}</td>
                    <td>{statisticsData.unsatisfactory}</td>
                    <td>{statisticsData.below_expectation}</td>
                    <td>{statisticsData.average}</td>
                    <td>{statisticsData.good}</td>
                    <td>{statisticsData.excellent}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
