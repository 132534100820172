import React, { useState } from "react";
import "../../assets/sass/_login.scss";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../Components/Config/Config";
import Logo from "../../assets/images/logo.png";

const ResetPassword = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(baseURL + "login", {
        email: email,
        password: password,
      });

      const { access_token } = response.data;
      localStorage.setItem("token", access_token);
      setIsLoggedIn(true);
      navigate("/dashboard");
    } catch (error) {
      setError("Invalid email or password. Please try again.");
    }
  };
  return (
    <div className="login-main">
      <div className="login-aside">
        <img className="img-fluid" src={Logo} alt="logo" />
        <h5>
          Welcome to Feedback Form SaaS App - Revolutionizing Client Feedback!
        </h5>
        <p>
          We understand the value of client feedback in shaping businesses for
          success.
        </p>
      </div>
      <div className="login-cont">
        <div>
          <h2>Password Reset</h2>
          <h3>We Will Help You Reset your Password</h3>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">
                Email<sup>*</sup>
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>

            {error && <p className="error">{error}</p>}
            <button className="btn btn-default" type="submit">
              Reset Password
            </button>
          </form>
          <p>
            Remember your Password? <Link to="/">Sign In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
